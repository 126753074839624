.home{
    background-image: linear-gradient(to top,rgba(30,30,30,0.60),rgba(30,30,30,0.2),rgba(255, 255, 255, 0.6)),url(./home.png);
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    height: 100vh;
}
.home main{
    padding-top: 90px;
    color: white;
    display: flex;
    justify-content: start;
    align-items: center;
}
.home main .left{
    max-width: 850px;
    text-align: start;
    margin-top: 100px;
    letter-spacing: 1.2px;
    padding-left: 150px;
}
.home main .left h1{
    font-size: 40px;
    padding: 10px 0;
    font-family: "Dancing Script", cursive;
    font-optical-sizing:auto;
}
.home main .left p{
    font-size: 20px;
    padding: 5px 0px;
    backdrop-filter: blur(1px);
    border-radius: 10px;
    margin-bottom: 30px;
}
.home main .left a{
    background-color: rgb(183, 140, 0);
    color: white;
    padding: 12px 24px;
    border-radius:5px;
    transition: 0.5s ease;
}
.home main .left a:hover{
    background-color: rgb(214, 0, 0);
    border-radius: 20px;
}
@media(max-width:786px){
    .home main .left{
        padding-left: 20px;
        margin-top: 10px;
    }
    .home main .left h1{
        font-size: 25px;
        margin-top: 70px;
    }
    .home main .left p{
        font-size: 18px;
    }
}

