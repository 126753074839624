/* Initially transparent */
nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: transparent;
    position: fixed;
    width: 100%;
    padding: 5px 30px;
    z-index: 999;
    transition: background-color 0.3s ease-in-out;
}

/* Background color after scrolling */
nav.scrolled {
    backdrop-filter: blur(5px);
    box-shadow: 0 0 2px 0 grey;
}

nav img {
    width: 110px;
    object-fit: cover;
}

nav a {
    font-size: 18px;
    margin: 0 35px;
    font-weight: bold;
}

nav a.inactive {
    color: rgb(6, 5, 0);
}

nav a.inactive:hover {
    color: rgb(198, 162, 0);
}

nav a.active {
    color: rgb(207, 0, 0);
    font-weight: bold;
}

.menu-icon {
    display: none;
    font-size: 2.2rem;
    cursor: pointer;
    color: rgb(109, 0, 0);
}

.nav-links {
    display: flex;
    gap: 35px;
}
@media (max-width: 786px) {
    .menu-icon {
        display: block;
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 1001;
    }

    .nav-links {
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.9);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
        z-index: 1000;
        backdrop-filter: blur(5px);
        overflow-y: hidden;
        padding-top: 80px; /* Space for the close button */
    }

    .nav-links.active {
        transform: translateX(0);
    }

    .menu-icon.close-icon {
        top: 20px;
        right: 20px;
        font-size: 2.5rem;
        color: white;
        z-index: 1002;
    }

    nav a.inactive {
        color: rgb(255, 255, 255);
    }

    .nav-links a {
        font-size: 2.2rem;
        margin: 20px 0;
        color: white;
    }

    nav.mobile-menu-open ~ * {
        filter: blur(5px);
        pointer-events: none;
    }

    nav {
        padding: 10px 0;
        margin:0;
        justify-content: space-between;
    }
}

