.contact {
  background: grey;
  background-image: linear-gradient(
      to top,
      rgba(30, 30, 30, 0.9),
      rgba(30, 30, 30, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    url(./path1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.contact main {
  padding-top: 50px;
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.contact main .left {
  max-width: 850px;
  text-align: start;
  letter-spacing: 1.2px;
}

.contact main .left h1 {
  font-size: 25px;
  padding: 10px 0;
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
}

.contact main .left p {
  font-size: 20px;
  padding: 5px 0px;
}

.contact-forms-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

.contact-form-container {
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  box-shadow:  0 0 10px 0  rgb(143, 143, 143);
  margin: 10px;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-form label {
  margin-bottom: 0.5rem;
  color: #555;
}

.contact-form input[type="text"],
.contact-form input[type="phone"],
.contact-form input[type="email"],
.contact-form textarea {
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  max-width: 100%;
}

.contact-form textarea {
  resize: vertical;
  min-height: 100px;
}

.contact-form input[type="submit"] {
  padding: 0.75rem;
  background-color: #d7a717;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  transition:0.6s ease;
}

.contact-form input[type="submit"]:hover {
  background-color: #cd1414;
  border-radius: 20px;
}
@media(max-width:1150px){
  .contact main {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

  }
  .contact-forms-outer {
    padding: 0;
  }
  .contact-form-container {

    margin-top: 0;
  }
  #optional {
    display: none;
  }
}

@media (max-width: 786px) {
  
  .contact main .left h1 {
    font-size: 20px;
    margin-top: 20px;
  }
  
  .contact main .left p {
    font-size: 18px;
  }
  
  .contact-forms-outer {
    padding: 0;
  }

  
  .contact-form-container {
    padding: 1.4rem 1.8rem;
  }
  
  .contact-form input[type="text"],
  .contact-form input[type="phone"],
  .contact-form input[type="email"],
  .contact-form textarea {
    font-size: 0.9rem;
  }

  .contact-form input[type="submit"] {
    font-size: 0.9rem;
  }
}
