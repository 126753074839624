.footer{
    background-color: rgb(230, 230, 230);
    padding: 40px 0;
}
.footer .inner-footer{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 30px 0;
}
.footer .first{
    padding: 0 20px;
    margin-bottom: 50px;
}
.footer .inner-footer .footer-logo img{
    width: 180px;
}
.footer .second{
    padding: 0 20px;
    margin-bottom: 50px;
}
.footer .second .social-media{
    margin-top: 20px;
}
.footer .second a{
    font-size: 30px;
    padding-right: 30px;
    color: rgb(217, 0, 0);
}
 .footer .second a:hover{
    color: rgb(207, 163, 32);
}
.footer .third{
    padding: 0 20px;
    margin-bottom: 50px;
}
.footer .third img{
    margin-top: 20px;
    width: 350px;
}

.copyright{
    text-align: center;
    padding-top: 20px;
    margin-top: 20px;
}
@media(max-width:786px){
    .footer .inner-footer{
        justify-content: start;
    }
}