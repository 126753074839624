.about{
  background: grey;
  background-image: linear-gradient(to top,rgba(30,30,30,0.90),rgba(30,30,30,0.6),rgba(255, 255, 255, 0.6)),url(./reception.jpg);
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center;
  height: 100vh;
}
.about main{
  padding-top: 90px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about main .left{
  max-width: 850px;
  text-align: center;
  margin-top: 100px;
  letter-spacing: 1.2px;
}
.about main .left h1{
  font-size: 35px;
  padding: 10px 0;
  font-family: "Dancing Script", cursive;
  font-optical-sizing:auto;
}
.about main .left p{
  font-size: 20px;
  padding: 5px 0px;
  backdrop-filter: blur(1px);
  border-radius: 10px;
  margin-bottom: 30px;
}
.about main .left a{
  background-color: rgb(183, 140, 0);
  color: white;
  padding: 12px 24px;
  border-radius:5px;
  transition: 0.5s ease;
}
.about main .left a:hover{
  background-color: rgb(214, 0, 0);
  border-radius: 20px;
}
@media(max-width:786px){
  .about main .left{
      padding-left: 20px;
      margin-top: 10px;
  }
  .about main .left h1{
      font-size: 20px;
      margin-top: 70px;
  }
  .about main .left p{
      font-size: 18px;
  }
}

