.content1{
    max-width: 800px;
    text-align: center;
    margin: 30px auto;
    padding: 0 10px;
}
.content1 h1{
    font-size: 45px;
}
.content1 p{
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 1px;
}
.content1 a{
    background-color: rgb(183, 140, 0);
    color: white;
    padding: 12px 24px;
    border-radius:5px;
    transition: 0.5s ease;
}
.content1 a:hover{
    background-color: rgb(214, 0, 0);
    border-radius: 20px;
}