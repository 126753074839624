.rooms{
    background: grey;
    background-image: linear-gradient(to top,rgba(30,30,30,0.90),rgba(30,30,30,0.6),rgba(255, 255, 255, 0.6)),url(./room1.jpg);
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    height: 100vh;
    overflow-x: hidden;
  }
  .rooms main{
    padding-top: 90px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rooms main .left{
    max-width: 850px;
    text-align: center;
    margin-top: 100px;
    letter-spacing: 1.2px;
  }
  .rooms main .left h1{
    font-size: 35px;
    padding: 10px 0;
    font-family: "Dancing Script", cursive;
    font-optical-sizing:auto;
  }
  .rooms main .left p{
    font-size: 20px;
    padding: 5px 0px;
    backdrop-filter: blur(1px);
    border-radius: 10px;
    margin-bottom: 30px;
  }
  .rooms main .left a{
    background-color: rgb(183, 140, 0);
    color: white;
    padding: 12px 24px;
    border-radius:5px;
    transition: 0.5s ease;
  }
  .rooms main .left a:hover{
    background-color: rgb(214, 0, 0);
    border-radius: 20px;
  }
  @media(max-width:786px){
    .rooms main .left{
        padding-left: 20px;
        margin-top: 10px;
    }
    .rooms main .left h1{
        font-size: 20px;
        margin-top: 70px;
    }
    .rooms main .left p{
        font-size: 18px;
    }
  }
.rooms-grid{
  margin: 90px 0;
  overflow-x: hidden;
}
.room{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 80px 0;
}
.room .room-image{
  width: 500px;
  height: 500px;
  background-color: rgb(225, 192, 0);
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius:50% 50% 45% 55% / 57% 63% 37% 43%;
}
.room img{
  width: 400px;
  height: 350px;
  margin-top: 50px;
  border-radius: 5px;
  object-fit: cover;
}
.room .room-info{
  display: flex;
  flex-direction: column;
  padding: 0 200px;
}
.room .room-info h2{
  font-size: 45px;
  padding: 10px 0;
}
.room .room-info p{
  font-size: 25px;
  padding: 5px 0;
}
 .room .room-info a{
  background-color: rgb(183, 140, 0);
  color: white;
  padding: 12px 24px;
  border-radius:5px;
  transition: 0.5s ease;
  text-align: center;
 }
 @media(max-width:786px){
   .rooms-grid{
       margin-top: 60px;
   }
   .room .room-image{
       max-width: 400px;
       height: 350px;
   }
   .room .room-image img{
     width: 300px;
     height: 250px;
   }
   .room .room-info{
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .room .room-info h2{
    font-size: 35px;
    padding: 10px 0;
  }
  .room .room-info p{
    font-size: 20px;
    padding: 5px 0;
  }
   .room .room-info a{
    background-color: rgb(183, 140, 0);
    color: white;
    padding: 12px 24px;
    border-radius:5px;
    transition: 0.5s ease;
    text-align: center;
   }
  
 }