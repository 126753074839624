.rev-room {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 80px 0;
  }
  .rev-room .room-image{
    width: 500px;
    height: 500px;
    background-color: rgb(225, 0, 0);
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius:50% 50% 45% 55% / 57% 63% 37% 43%;
  }
  .rev-room img{
    width: 400px;
    height: 350px;
    margin-top: 50px;
    border-radius: 5px;
    object-fit: cover;
  }
  .rev-room .room-info{
    display: flex;
    flex-direction: column;
    padding: 0 200px;
  }
  .rev-room .room-info h2{
    font-size: 45px;
    padding: 10px 0;
  }
  .rev-room .room-info p{
    font-size: 25px;
    padding: 5px 0;
  }
   .rev-room .room-info a{
    background-color: rgb(183, 140, 0);
    color: white;
    padding: 12px 24px;
    border-radius:5px;
    transition: 0.5s ease;
    text-align: center;
   }
   @media(max-width:786px){
     .rooms-grid{
         margin-top: 60px;
     }
     .rev-room .room-image{
         max-width: 400px;
         height: 350px;
     }
     .rev-room .room-image img{
       width: 300px;
       height: 250px;
     }
     .rev-room .room-info{
      display: flex;
      flex-direction: column;
      padding: 0;
    }
    .rev-room .room-info h2{
      font-size: 35px;
      padding: 10px 0;
    }
    .rev-room .room-info p{
      font-size: 20px;
      padding: 5px 0;
    }
     .rev-room .room-info a{
      background-color: rgb(183, 140, 0);
      color: white;
      padding: 12px 24px;
      border-radius:5px;
      transition: 0.5s ease;
      text-align: center;
     }
    
   }