*{
  padding: 0;
  margin: 0;  
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px; 
}
body::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 5px; 
}
body::-webkit-scrollbar-thumb {
  background-color: #ff5729; 
  border-radius: 10px; 
  border: 2px solid #f1f1f1; 
}
body::-webkit-scrollbar-thumb:hover {
  background-color: #ce9e00d3; 
}
body {
  margin: 0;
  font-family: "Varela Round", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}
a{
  text-decoration: none;
}
h1{
  font-family: "Dancing Script", cursive;
  font-optical-sizing:auto;
}
ul{
  list-style: none;
}
img{
  cursor: pointer;
}