.details{
    background-color: rgba(255, 3, 3, 0.811);
    padding: 40px 0;
    text-align: center;
    color: white;
    text-align: center;
    margin: 30px auto;
}
.details h1{
    font-size: 45px;
}
.details p{
    max-width: 800px;
    text-align: center;
    margin: 30px auto;
    padding: 0 10px;
}
.detail{
    max-width: 800px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.near-places{
    max-width: 1000px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0;
    text-align: center;
    margin: 10px auto;
}
#near{
    font-size: 45px;
}
.near-places img{
    max-width: 400px;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
}
.near-places .place{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    margin: 30px 50px;
}
.near-places .place .place-info{
    margin-top: -45.5px;
    background: linear-gradient(rgba(147, 147, 147, 0),rgba(38, 38, 38, 0.3),rgba(38, 38, 38, 0.5),rgba(45, 45, 45,0.8));
    border-radius: 8px;
    color: white;
    width: 400px;
}
.near-places .place .place-info p{
    text-align: center;
    margin:0;
}
@media(max-width:786px){
    .near-places img{
        max-width: 350px;
        height: 300px;
    }
    .near-places .place{
        margin: 30px 0px;
    }
    .near-places .place .place-info{
        width: 350px;
    }
}